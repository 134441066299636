
:root {
  --link-color: #0061a0;
  --border-color: rgb(222, 226, 230);
}


html {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: rgba(240, 242, 245, 0.8);
}

body {
  margin: 0;
  padding: 0;
}

.p-button {
  margin-right: .5rem;
}


h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

.widgetContainer .p-panel .p-panel-content, .widgetContainer .p-tree {
  border-radius: initial;
  border: initial;
}

.widgetContainer .noPadding .p-panel-content {
  padding: initial;
}

.widgetContainer .p-panel-header {
  border-radius: initial;
  border: initial;
  border-top: solid 1px var(--surface-300);
  border-bottom: solid 1px var(--surface-300);
}

.widgetContainer .firstComponent .p-panel-header {
  border-bottom: solid 1px var(--surface-300);
  border-top: initial;
}

.overHere > button {
  animation: overHere .5s forwards;
}

@keyframes overHere {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(1.2)
  }

  100% {
    transform: scale(1)
  }
}

h1 {
  font-size: 2.5rem
}

h2 {
  font-size: 2rem
}

h3 {
  font-size: 1.75rem
}

h4 {
  font-size: 1.5rem
}

h5 {
  font-size: 1.25rem
}

h6 {
  font-size: 1rem
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.p-panel-title {
  font-size: 1.1rem;
}

.white-icon {
  color: white;
}

  .white-icon svg {
    fill: white;
  }

main.centered {
  max-width: 900px;
  margin: 0 auto;
}

section {
  margin-bottom: 15px;
}

  section h2:first-of-type {
    margin-top: 0;
  }

.smallIcon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
/* unvisited link */
a:link {
  color: var(--link-color);
  text-decoration: none;
}
/* visited link */
a:visited {
  color: var(--link-color);
  text-decoration: none;
}

/* selected link */
a:active {
  color: var(--link-color);
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: var(--blue-500);
  text-decoration: underline;
}

.status-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

  .status-badge.status-In_Preparation {
    background-color: #b3e5fc;
    color: #23547b;
  }

  .status-badge.status-Under_Review {
    background-color: #ffd8b2;
    color: #805b36;
  }

  .status-badge.status-Approved {
    background-color: #c8e6c9;
    color: #256029;
  }

.ag-theme-balham .ag-header {
  background-color: var( --header-bg);
}

.ag-theme-balham .ag-root-wrapper {
  border-color: var(--border-color);
}

.section_container .p-card .p-card-body {
  padding: 1rem !important;
}

div.p-datatable table {
  min-width: 100%;
  border-collapse: collapse;
}

table.display-table {
  border-collapse: collapse;
  width: 100%
}

  table.display-table thead > tr > th {
    text-align: left;
    padding: 0.5rem 0.5rem;
    border: 1px solid var( --border-color);
    border-width: 1px;
    font-weight: 600;
    color: #495057;
    background: var( --header-bg);
    transition: box-shadow 0.2s;
  }

  table.display-table tbody > tr > td {
    text-align: left;
    border: 1px solid var( --border-color);
    padding: 0.5rem 0.5rem;
  }

  table.display-table tbody > tr:hover {
    background-color: #00000005
  }

.p-overlaypanel-content, .p-overlaypanel {
  border-radius: 8px;
}

.p-tabview-nav-container {
  border-radius: 10px;
}


.form-control {
  margin-bottom: 5px;
}

  .form-control label {
    display: block;
  }

.details-table {
  width: 100%;
}

  .details-table th {
    width: 150px;
    text-align: left;
    vertical-align: top;
  }

  .details-table td {
    text-align: left;
    vertical-align: top;
  }

.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.2);
  z-index: 1500;
}

.loading-spinner {
  margin: 50px auto;
  width: 100px;
}


p {
  margin: 0;
  margin-block: 0;
}

.red {
  color: #C30721;
}

.bold {
  font-weight: bold;
}


nav.nav-main {
  background: #7F98AB;
}
nav.nav-main a {
  display: inline-block;
  padding: 5px 20px;
  color: #FFFFFF;
  text-decoration: none;
  font-size:12px;
}
nav.nav-main a:hover {
  text-decoration:underline;
}
nav.nav-main a.selected {
  background: #003158;
}

.width-300 {
  width: 300px;
}

input[readonly] {
  background-color: #F7F7F7;
}





.card {
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
